
export const fiserv_base_url = process?.env?.REACT_APP_FISERV_API_URL;
export const base_url = process?.env?.REACT_APP_API_URL;



export const API = {
  appleFinServer_Payment: `${fiserv_base_url}/finserve/apple_pay_payment`,
  apple_Pay_Session:`${fiserv_base_url}/finserve/get_apple_pay_session`,
  google_FinServer_Payment:`${fiserv_base_url}/finserve/google_pay_payment`,
  get_Menu_List:`${base_url}/qr_code_apis/get_qr_menus`,
  eber_Login :`${fiserv_base_url}/eber/get_user_info`,
  password_verify:`${fiserv_base_url}/eber/user/login/password`
}
