import React, {
  useEffect,
  useState,
  useRef,
  Fragment,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { DeviceUUID } from "device-uuid";
import {
  cameraIcon,
  existingUser,
  imagePlaceholderIcon,
  qrCodeIcon,
  userImgIcon,
} from "../../Assets";
import QrInput from "../Components/QR_code/QrInput";
import QrCamera from "../Components/QR_code/QrCamera";
import ImageTag from "../Components/ImageTag";
import { Motion, spring, presets } from "react-motion";
import {
  updateEmailInput,
  sendOtpDetail,
  getUserDetail,
  updateOtpInput,
  showNotificationWithTimeout,
  getLoyalityDetail,
  updatePhoneInput,
  sendPhoneDetail,
  updatePhoneCodeInput,
  updatePhonePinInput,
  togglePhonePinInput,
  updatePhoneAgreementInput,
  togglePhoneAgreementInput,
  changeXgateInput,
  getXgateCustomer,
  chnageLoginPop,
  eberLogin,
  updateMemberIdInput,
  getComoCustomer,
} from "../../Redux/actions";
import CountryCode from "../../Assets/country_codes.json";
import { connect, useSelector, useDispatch } from "react-redux";
import Modal from "../Components/Modal";
import LoginLayout from "../Components/LoginLayout";
import LoginInput from "../Components/LoginInput";
import LoginNewCustomer from "./LoginNewCustomer";
import CustomCheckBox from "../../Component/molecules/CustomCheckBox";
import { selectLogin, STRINGS } from "../../helpers";
import { Terms } from "../Components/Terms";
import { StyledSVG } from "../../Utils/style";
import CheckPassword from "./CheckPassword";
import UserConfirmation from "./UserConfirmation";

// MerchantLoyaltySetting?.LoyaltyPackage?.code
const LoginEber = () => {
  var uuid = "not-valid";
  let history = useHistory();
  const loaderReducer = useSelector((state) => state.loaderReducer);
  const tableReducer = useSelector((state) => state.tableDetailReducer);
  console.log("tableReducer=",tableReducer)
  const merchantId = tableReducer?.table_detail?.Merchant?.id;
  const loyaltyEnabled =
    tableReducer?.table_detail?.Merchant?.loyalty_enabled ?? false;
  const loyaltyPackage =
    tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting
      ?.LoyaltyPackage ?? null;
  const tableNumber =
    tableReducer?.table_detail?.PosTableMaster?.table_number ?? "";
  const deviceId = uuid;
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authDetailReducer);
  const email = authReducer.email_input;
  const otp = authReducer.otp_input;
  const phone = authReducer.phone_input;
  const member_id = authReducer.member_id;

  const phone_pin_input = authReducer.phone_pin_input;
  const phone_agreement_input = authReducer.phone_agreement_input;
  const phone_code =
    authReducer.phone_code_input == "" ? "+65" : authReducer.phone_code_input;
  const [scannerModal, setScannerModal] = useState(false);
  const [scannerInvalidModal, setScannerInvalidModal] = useState(false);
  const [showFileInput, setShowFileInput] = useState(false);
  const scannerInvalidModalRef = useRef();
  scannerInvalidModalRef.current = scannerInvalidModal;
  const showFileInputRef = useRef();
  showFileInputRef.current = showFileInput;

  const [cameraInvalidModal, setCameraInvalidModal] = useState(false);
  const [loyaltyPopUp, setLoyaltyPopUp] = useState(false);
  const [loginNew, setLoginNew] = useState(false);
  const [redirectPassword, setRedirectrPassword] = useState(false);
  // const [userData,setUserData]=useState(null);

  const qrInputRef = useRef("qrInputRef");
  const qrCameraRef = useRef("qrCameraRef");

  
  const { table_detail } = useSelector((state) => state.tableDetailReducer);
  const passwordRequired=table_detail?.Merchant?.MerchantLoyaltySetting?.require_password_authentication


    // console.log("passwordRequired=",passwordRequired)

  useEffect(() => {
    // redirect / if not have table detail

    if (
      loaderReducer?.loading === false &&
      tableReducer?.table_detail?.show_table_not_found_modal === false &&
      (tableReducer?.table_detail?.Merchant?.id === "" ||
        tableReducer?.table_detail?.Merchant?.id === null ||
        tableReducer?.table_detail?.Merchant?.id === undefined ||
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id === "" ||
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id === null ||
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id === undefined)
    ) {
      history.push("/404");
    }
    // if already login send to menu page
    if (
      tableReducer?.table_detail?.PosTableMaster?.qr_code_id !== undefined &&
      loaderReducer?.loading === false &&
      authReducer?.user_detail !== null &&
      authReducer?.user_detail !== undefined
    ) {
      history.push(
        `/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`
      );
    }
  }, []);
   const submitHandler = (el) => {
     el.preventDefault();

      // if()
     console.log(userDetails);

     if((passwordRequired==="yes" && !userDetails?.memberID))
           setRedirectrPassword(true)

      else{
        if (loyaltyPackage?.code == "eber") {
          const payload = {
            merchant_id: merchantId ? merchantId : "",
            email: userDetails?.email ? userDetails?.email : "",
            phone: userDetails?.mobile ? userDetails?.mobile : "",
            external_member_id: userDetails?.memberID ? userDetails?.memberID : "",
            loyaltyType:loyaltyPackage?.code??""
          };
          
          dispatch(eberLogin(payload));
        }
        if (loyaltyPackage?.code == "como")   {
          const payload = {
            merchant_id: merchantId ? merchantId : "",
            email: userDetails?.email ? userDetails?.email : "",
            phone: userDetails?.mobile ? userDetails?.mobile : "",
            external_member_id: userDetails?.memberID ? userDetails?.memberID : "",
            loyalty_type:loyaltyPackage?.code??""
          };
          dispatch(getComoCustomer(payload));
    
        }
        if (loyaltyPackage?.code == "xgate") {
          const xgateInputs = {
            merchantId: merchantId,
            tableNumber: tableNumber,
            loyalty_type:loyaltyPackage?.code??""
          };
          
            dispatch(getXgateCustomer(xgateInputs));
        }
      }    

      
   
 
  };

  const phoneSubmitHandler = (el) => {
    el.preventDefault();
    if (authReducer?.show_phone_pin_input === true && phone_pin_input == "") {
      return dispatch(
        showNotificationWithTimeout(`Please enter pin!`, "error")
      );
    }
    dispatch(
      sendPhoneDetail(
        phone,
        phone_code,
        phone_pin_input,
        false,
        merchantId,
        deviceId,
        tableNumber
      )
    );
  };

  const guestSubmitHandler = (el) => {
    el.preventDefault();
    dispatch(
      getUserDetail({
        email: "",
        merchantId: merchantId,
        deviceId: deviceId,
        tableNumber: tableNumber,
      })
    );
  };

  useEffect(() => {
    if (
      showFileInputRef.current == true &&
      scannerInvalidModalRef.current == false &&
      qrInputRef.current
    ) {
      qrInputRef.current.click();
    }
  }, [scannerInvalidModal, showFileInput]);


  // useEffect(()=>{
  //     // const userInfo=JSON.parse(localStorage.getItem("user_info"));
  //   // setUserData(userInfo);
  //   // console.log("localStorage=",userInfo)
  // },[redirectPassword])

  const emailChangeHandler = (el) => {
    dispatch(updateEmailInput(el.target.value));
  };
  const memberId = (el) => {
    dispatch(updateMemberIdInput(el.target.value));
  };

  const otpChangeHandler = (el) => {
    dispatch(updateOtpInput(el.target.value));
  };
  const phonePinChangeHandler = (el) => {
    dispatch(updatePhonePinInput(el.target.value));
  };

  const phoneChangeHandler = (el) => {
    dispatch(updatePhoneInput(el.target.value));
  };
  const phoneCodeChangeHandler = (el) => {
    dispatch(updatePhoneCodeInput(el.target.value));
  };

  const scannerInvalidModalCancel = useCallback(() => {
    // setScannerInvalidModal(false);
  }, []);

  const scannerInvalidModalSubmit = useCallback(() => {
    // setScannerInvalidModal(false);
    setShowFileInput(true);
    // qrInputRef.current.click();
  }, []);

  const cameraInvalidModalCancel = useCallback(() => {
    setCameraInvalidModal(false);
  }, []);

  const cameraInvalidModalSubmit = useCallback(() => {
    setCameraInvalidModal(false);
    setScannerModal(true);
  }, []);

  const scannerHandleSuccess = useCallback(
    (res) => {
      // console.log("qr_scan", res);
      const scanArr = res.replace(/^(https?:\/\/)/, "");
      console.log("qr_scan", scanArr);

      if (scanArr.length > 2) {
        const payload = {
          merchant_id: merchantId ? merchantId : "",
          qr_code: scanArr,
        };
        dispatch(eberLogin(payload));
      } else {
        setScannerModal(false);
        setScannerInvalidModal(true);
      }
    },
    [tableReducer]
  );
  const scannerHandleError = useCallback(
    (err) => {
      dispatch(showNotificationWithTimeout(err, "error"));
    },
    [tableReducer]
  );
  const handleCheckboxChange = (event) => {
    setLoginType(event.target.value);
    setUserDetails({});
  };

  const [loginType, setLoginType] = useState(1);
  const [userDetails, setUserDetails] = useState({});
  const [showTermsPopup, setShowTermsPopup] = useState(false);


  const chnageInputHandle = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });

  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className="group-sec">
          <div className="title-heading-login">
            {tableReducer?.table_detail?.Merchant?.account_name}
          </div>
        </div>
        <div
              className="login-new d-flex align-items-center my-5"
            >
              <StyledSVG src={existingUser} height={21} width={21}/>
            <span className="ml-3">EXISTING CUSTOMER</span>
            </div>

        {selectLogin(loyaltyPackage?.code).map((data, index) => {
          console.log( passwordRequired==="yes" && (data.value!==STRINGS.MEMBER_ID_VALUE && data.value!==STRINGS.SCAN_QR_VALUE)  )
          return (
            passwordRequired==="yes"?(
            data.value!==STRINGS.MEMBER_ID_VALUE && data.value!==STRINGS.SCAN_QR_VALUE?         
            <CustomCheckBox
              key={data.id + index}
              data={data}
              chnageHandle={handleCheckboxChange}
              loginType={loginType}
              chnageInputHandle={chnageInputHandle}
              setScannerModal={setScannerModal}
              tableReducer={tableReducer}
              setShowFileInput={setShowFileInput}
              qrInputRef={qrInputRef}
              scannerInvalidModal={scannerInvalidModal}
              scannerHandleSuccess={scannerHandleSuccess}
              scannerHandleError={scannerHandleError}
              imagePlaceholderIcon={imagePlaceholderIcon}
            />:
            null):
            <CustomCheckBox
            key={data.id + index}
            data={data}
            chnageHandle={handleCheckboxChange}
            loginType={loginType}
            chnageInputHandle={chnageInputHandle}
            setScannerModal={setScannerModal}
            tableReducer={tableReducer}
            setShowFileInput={setShowFileInput}
            qrInputRef={qrInputRef}
            scannerInvalidModal={scannerInvalidModal}
            scannerHandleSuccess={scannerHandleSuccess}
            scannerHandleError={scannerHandleError}
            imagePlaceholderIcon={imagePlaceholderIcon}
          />



          // <CustomCheckBox
          //   key={data.id + index}
          //   data={data}
          //   chnageHandle={handleCheckboxChange}
          //   loginType={loginType}
          //   chnageInputHandle={chnageInputHandle}
          //   setScannerModal={setScannerModal}
          //   tableReducer={tableReducer}
          //   setShowFileInput={setShowFileInput}
          //   qrInputRef={qrInputRef}
          //   scannerInvalidModal={scannerInvalidModal}
          //   scannerHandleSuccess={scannerHandleSuccess}
          //   scannerHandleError={scannerHandleError}
          //   imagePlaceholderIcon={imagePlaceholderIcon}
          // />



          );
        })}
        <div className="resend_btn_row my-4 mt-5">
         
          <button
            type="submit shadow"
            style={{
              backgroundColor:
                tableReducer?.table_detail?.QrOrdering?.brand_main_color,
            }}
            className="btn green-btn new-green"
          >
            Submit
          </button>
        </div>
      </form>
      <h4>
        By clicking <strong>‘Submit’</strong>, I agree and accept to GoGMGo’s{" "}
        <strong onClick={() => setShowTermsPopup(true)} className="privacy-policy" >Terms of Use</strong> and{" "}
        <strong
          onClick={() =>
            window.open("https://www.gogmgo.com/privacy-policy/", "_newtab")
          }
          className="privacy-policy"
        >
          Privacy Policy
        </strong>
      </h4>
      <div className="or">
        {" "}
        <span> Or </span>{" "}
      </div>
      <div className="guest_login my-3 mb-80">
        <a
          href="javascript:void(0)"
          className="btn green-btn new-green"
          onClick={guestSubmitHandler}
          style={{
            backgroundColor:
              tableReducer?.table_detail?.QrOrdering?.brand_main_color,
          }}
        >
          Continue as a guest
        </a>
      </div>
      <section className={`qrcode_modal ${scannerModal ? "show" : ""}`}>
        <div className="top">
          <h4>Scan QR Code</h4>
        </div>
        {scannerModal && (
          <QrCamera
            class="qr-camera-container"
            handleScan={(res) => {
              if (res) {
                console.log("res ==================================== ", res);
                const scanArr = res.replace(/^(https?:\/\/)/, "");
                if (scanArr.length > 2) {
                  const payload = {
                    merchant_id: merchantId ? merchantId : "",
                    qr_code: scanArr,
                  };
                  dispatch(eberLogin(payload));
                } else {
                  setScannerModal(false);
                  setScannerInvalidModal(true);
                }
              }
            }}
            ref={qrCameraRef}
            onError={(err) =>
              dispatch(showNotificationWithTimeout(err, "error"))
            }
          />
        )}

        {/* <div className="screen">
              <p className="msg">Place code inside the box. </p>
            </div> */}
        <section class="bottom-sec">
          <div
            class="green-sec"
            onClick={() => {
              document.body.classList.remove("modal-open");
              setScannerModal(false);
            }}
          >
            Cancel
          </div>
        </section>
      </section>
      <section
        className={`phone_agreement_modal  ${
          authReducer?.show_phone_agreement_input === true ? "show" : ""
        }`}
      >
        <div className="top">
          <h4>Terms & Condition</h4>
        </div>
        <div
          className="Container"
          dangerouslySetInnerHTML={{
            __html: authReducer?.phone_agreement_detail ?? "",
          }}
        ></div>
        <section class="bottom-sec">
          <div
            class="green-sec"
            onClick={(e) => {
              dispatch(
                sendPhoneDetail(
                  phone,
                  phone_code,
                  phone_pin_input,
                  true,
                  merchantId,
                  deviceId,
                  tableNumber
                )
              );
            }}
          >
            Agree
          </div>
          <div
            class="green-sec red"
            onClick={() => {
              document.body.classList.remove("modal-open");
              dispatch(togglePhoneAgreementInput(false));
            }}
          >
            Cancel
          </div>
        </section>
      </section>
      <Modal
        show={showTermsPopup}
        title={""}
        extraClass={"top50"}
        modalbody={<Terms />}
        OnSubmit={() => setShowTermsPopup(false)}
        submitBtnText="ok"
      />
      <Modal
        show={scannerInvalidModal}
        title="Invalid QR code!"
        onCancel={scannerInvalidModalCancel}
        OnSubmit={scannerInvalidModalSubmit}
        cancelBtnText="Cancel"
        submitBtnText="Retry"
      />

      <Modal
        show={cameraInvalidModal}
        title="Invalid QR code!"
        onCancel={cameraInvalidModalCancel}
        OnSubmit={cameraInvalidModalSubmit}
        cancelBtnText="Cancel"
        submitBtnText="Retry"
      />

      <Modal
        show={authReducer.showNewLoginPopUp}
        title="Email not recognized. Do you want to try again or signup for a non-loyalty customer account?"
        onCancel={() => dispatch(chnageLoginPop(false))}
        OnSubmit={() => {
          dispatch(chnageLoginPop(false));
          setLoginNew(true);
        }}
        cancelBtnText="Try Again"
        submitBtnText="Sign-up"
      />

      <Motion
        defaultStyle={{ x: 100 }}
        style={{
          x: spring(loginNew ? 0 : 100, {
            precision: 1,
            stiffness: 300,
            damping: 44,
          }),
        }}
      >
        {({ x }) => {
          return (
            <LoginNewCustomer
              style={{
                right: `-${x}%`,
              }}
              customerType={loginNew}
              setCustomerType={setLoginNew}
            />
          );
        }}
      </Motion>


      <Motion
        defaultStyle={{ x: 100 }}
        style={{
          x: spring(redirectPassword ? 0 : 100, {
            precision: 1,
            stiffness: 300,
            damping: 44,
          }),
        }}
      >
        {({ x }) => {
            console.log(passwordRequired)
          return (
              (passwordRequired==="yes")?(
               ( <CheckPassword
                  style={{
                    right: `-${x}%`,
                  }}
                  customerType={loginNew}
                  userDetails={userDetails}
                  setCustomerType={setLoginNew}
                  setRedirectrPassword={setRedirectrPassword}
                  />)
               
  ):(null) 
          );
        }}
      </Motion>



    </>
  );
};

export default LoginEber;
