import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginLayout from "../Components/LoginLayout";
import LoginInput from "../Components/LoginInput";
import { useHistory } from "react-router-dom";
import {
  eberLogin,
  emptyCustomerDetail,
  emptyTableDetail,
  emptyUserDetail,
  getUserDetail,
  sendOtpDetail,
  updateEmailInput,
  updateOtpInput,
} from "../../Redux/actions";
import { StyledSVG } from "../../Utils/style";
import { envelope, green_loc_mark, newUser } from "../../Assets";
import Modal from "../Components/Modal";
import { Terms } from "../Components/Terms";
import { Motion, spring } from "react-motion";
import LoginEber from "./LoginEber";

const UserConfirmation = ({ style, customerType, setCustomerType,userData,setRedirectrPassword }) => {
  var uuid = "not-valid";
  const {eber_details}=useSelector((state)=>state.authDetailReducer)
  console.log("userData=",eber_details)
  let history = useHistory();
  const loaderReducer = useSelector((state) => state.loaderReducer);
  const tableReducer = useSelector((state) => state.tableDetailReducer);
  console.log("tableReducer=",tableReducer)

  console.log(tableReducer?.table_detail)
  if(tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting?.require_password_authentication==="no"){
      //  localStorage.removeItem("user_info")
      
    // history.push("/login");      
  }


  const merchantId = tableReducer?.table_detail?.Merchant?.id;
  const loyaltyEnabled =
    tableReducer?.table_detail?.Merchant?.loyalty_enabled ?? false;
  const loyaltyPackage =
    tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting
      ?.LoyaltyPackage ?? null;

  const tableNumber =
    tableReducer?.table_detail?.PosTableMaster?.table_number ?? "";
  const deviceId = uuid;
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authDetailReducer);
  const email = authReducer.email_input;
  const otp = authReducer.otp_input;
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [redirectEberLogin,setRedirectEberLogin]=useState(false)
  // const localStorageData=JSON.parse(localStorage.getItem("user_info"))|| JSON.parse(getData("user_info"))
  // const merchantId = tableReducer?.table_detail?.Merchant?.id;

  console.log(tableReducer)
  // const [customerType, setCustomerType] = useState("");
  const backBtnHandler = () => {
    console.log("setCustomerType", customerType);
    // setRedirectrPassword(false)
    setCustomerType("");
  };

  const differentUserSubmitHandler = (el) => {

    // localStorage.removeItem(`user_info_${merchantId}`);
    dispatch(emptyCustomerDetail())
    history.push("/login");
    // setRedirectEberLogin(true)
    // setRedirectrPassword(false)
    // dispatch(
    //   getUserDetail({
    //     email: "",
    //     merchantId: merchantId,
    //     deviceId: deviceId,
    //     tableNumber: tableNumber,
    //   })
    // );
  };

  useEffect(() => {

  //   // redirect / if not have table detail
  //   if (
  //     loaderReducer?.loading === false &&
  //     tableReducer?.table_detail?.show_table_not_found_modal === false &&
  //     (tableReducer?.table_detail?.Merchant?.id === "" ||
  //       tableReducer?.table_detail?.Merchant?.id === null ||
  //       tableReducer?.table_detail?.Merchant?.id === undefined ||
  //       tableReducer?.table_detail?.PosTableMaster?.qr_code_id === "" ||
  //       tableReducer?.table_detail?.PosTableMaster?.qr_code_id === null ||
  //       tableReducer?.table_detail?.PosTableMaster?.qr_code_id === undefined)
  //   ) {
  //     history.push("/404");
  //   }
  //   // if already login send to menu page
  //   if (
  //     tableReducer?.table_detail?.PosTableMaster?.qr_code_id !== undefined &&
  //     loaderReducer?.loading === false &&
  //     authReducer?.user_detail !== null &&
  //     authReducer?.user_detail !== undefined
  //   ) {
  //     history.push(
  //       `/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`
  //     );
  //   }

  //   // history.push(
  //   //   `/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`
  //   // );



  // const payload = {
  //   merchant_id: merchantId ? merchantId : "",
  //   email: localStorageData?.user_email ? localStorageData?.user_email : "",
  //   phone: localStorageData?.mobile ? localStorageData?.mobile : "",
  //   external_member_id: localStorageData?.memberID ? localStorageData?.memberID : "",
  // };
  // console.log()
  
  //  dispatch(eberLogin(payload));



  // dispatch(eberLogin(payload));
  if(eber_details)
  history.push(
    `/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`
  );

  }, [eber_details]);




 



  const submitHandler = (el) => {
    el.preventDefault();
    // history.push(`/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`)
    // dispatch(sendOtpDetail(email, otp, merchantId, deviceId, tableNumber));
    // dispatch(eberLogin())
    // dispatch(getUserDetail({
    //     email: localStorageData?.user_email ?? "",
    //     merchantId: merchantId,
    //     // deviceId: action?.payload?.deviceId ?? "",
    //     // tableNumber: action?.payload?.tableNumber ?? "",
    //     phoneDetail: localStorageData?.user_phone ?? null,
    //     // code: membershipObj?.comoMemberId ?? "", 
    // }))


    // if (
    //   tableReducer?.table_detail?.PosTableMaster?.qr_code_id !== undefined &&
    //   loaderReducer?.loading === false &&
    //   authReducer?.user_detail !== null &&
    //   authReducer?.user_detail !== undefined
    // ) {


      // const payload = {
      //   merchant_id: merchantId ? merchantId : "",
      //   email: localStorageData?.user_email ? localStorageData?.user_email : "",
      //   phone: localStorageData?.mobile ? localStorageData?.mobile : "",
      //   external_member_id: localStorageData?.memberID ? localStorageData?.memberID : "",
      // };
      // console.log()
      
      //  dispatch(eberLogin(payload));

    
      const payload = {
        merchant_id: merchantId ? merchantId : "",
        email: tableReducer?.table_detail?.Customer?.contact_email ?tableReducer?.table_detail?.Customer?.contact_email : "",
        phone:(tableReducer?.table_detail?.Customer?.contact_number && 
         tableReducer.table_detail.Customer.contact_number.startsWith('+91'))
          ? tableReducer.table_detail.Customer.contact_number.replace('+91', '')
         : tableReducer?.table_detail?.Customer?.contact_number || "",
        // phone:"98688178",

        external_member_id: tableReducer?.table_detail?.Customer?.external_member_id ?tableReducer?.table_detail?.Customer?.external_member_id : "",
        loyaltyType:tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting?.LoyaltyPackage?.code?
                      tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting?.LoyaltyPackage?.code:"",
      };
      // console.log(mer)
      
      //  dispatch(eberLogin(payload));
    
    
    
      dispatch(eberLogin(payload));
    


      // history.push(
      //   `/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`
      // );
      // dispatch(eberLogin(payload));
    // }



  };

  // const 

  const resendSubmitHandler = (el) => {
    dispatch(sendOtpDetail(email, "", merchantId, deviceId));
  };
  const emailChangeHandler = (el) => {
    dispatch(updateEmailInput(el.target.value));
  };

  const otpChangeHandler = (el) => {
    dispatch(updateOtpInput(el.target.value));
  };

  return (
    <LoginLayout
      style={style}
      divClass="cart_sidebar"
      backHandler={backBtnHandler}
      showBackBtn={false}
    >
      <div className="login-form pt-1 px_15">
        <h3 className="merchant-title d-flex mt-2 justify-content-center">
        {tableReducer?.table_detail?.Merchant?.account_name}
        </h3>
        <form onSubmit={submitHandler} className="mx-5">
          <div className="group-sec">
            <div
              className="new-customer-text my-5"
              // style={{color:tableReducer?.table_detail?.QrOrdering?.brand_main_color}}
            >
              <h5 className="ml-3 input-title-center ">Is that you, {tableReducer?.table_detail?.Customer?.first_name}? </h5> 
              {/* <h5 className="ml-2 mb-0 input-title  ">{localStorageData?.user_display_name}</h5> */}
            </div>

            <div
              className="green-box"
              style={{
                paddingBottom:"0",
                width:"20rem",
                display:"flex",
                margin:"auto",
                border: `2px solid ${tableReducer?.table_detail?.QrOrdering?.brand_main_color}`,
              }}
            >
             {/* <div style={
                 {
                  // height:"1vh",
                  width:"4vw",
                  // border:"2px red solid"
                 }
             }>
             <span className="existCust"></span>
              </div>  */}
                <div className="group" style={{
                     width:"4rem"

                }}>
                   {/* <label for="newCustomer"> */}
                      {/* {" "} */}
                      <span className="existCust" style={{
                          //  size:"2rem"
                      }}></span>
                      {/* <div> */}


              {/* <StyledSVG  src={green_loc_mark} width="20" /> */}
                        {/* <h4>New </h4> <h4>Customer </h4> */}
                      {/* </div> */}
                    {/* </label>  */}
                  </div>

                  <div style={{
                       padding:"2%",
                       width:"20rem",
                      textAlign:"start"

                  }}>
                  <p> 
             <strong>{tableReducer?.table_detail?.Customer?.first_name}</strong> 
              </p>
              <p className="input-title">
             Member ID: {tableReducer?.table_detail?.Customer?.external_member_id?tableReducer?.table_detail?.Customer?.user_external_member_id:tableReducer?.table_detail?.Customer?.id} 
              </p>
                    {/* <label for="newCustomer">
                      {" "}
                      <span className="newCust"></span>
                      <div>
                        <h4>New </h4> <h4>Customer </h4>
                      </div>
                    </label> */}
                  </div>


             



            {/* <div>
             <p> 
             <strong>{localStorageData?.user_display_name},</strong> 
              </p>
              <p >
             Member ID:{localStorageData?.user_external_member_id?localStorageData?.user_external_member_id:localStorageData?.user_id} 
              </p>
            </div> */}


            </div>
          </div>
          {/* <div className="title-heading d-flex align-items-center ">
            <StyledSVG src={envelope}/>
            <span className="mx-2 otp-text">Submit email for OTP verification{" "}</span>
          </div> */}

          {/* <LoginInput
            placeholder={"Email"}
            value={authReducer?.email_input ?? ""}
            onChange={emailChangeHandler}
          /> */}
          {/* {authReducer?.show_otp_input === true && (
            <LoginInput
              placeholder="Enter OTP"
              value={authReducer?.otp_input ?? ""}
              onChange={otpChangeHandler}
            />
          )} */}

          <div className="resend_btn_row my-4">
            {/* {authReducer?.show_otp_input === true && (
              <button
                type="button"
                className="btn green-btn new-green"
                onClick={resendSubmitHandler}
              >
                Resend
              </button>
            )} */}
            <button
              type="submit"
              className="btn green-btn new-green"
              style={{
                backgroundColor:
                  tableReducer?.table_detail?.QrOrdering?.brand_main_color,
              }}
            >
              Yes, that's me
            </button>
          </div>
          {/* <div className="col-12">
            <p className="my-1 text-center">
              By clicking <b>'Submit'</b>, I agree and accept to GoGMGo's{" "}
              <b onClick={() => setShowTermsPopup(true)}>Terms of Use</b> and{" "}
              <b
                onClick={() =>
                  window.open(
                    "https://www.gogmgo.com/privacy-policy/",
                    "_newtab"
                  )
                }
              >
                Privacy Policy
              </b>
            </p>
          </div> */}
        </form>
        <div className="or" style={{
          marginTop:"10px"
        }}>
          {" "}
          <span> Or </span>{" "}
        </div>
        <div className="guest_login mt-3 mb-80" >
          <a
            href="javascript:void(0)"
            className="btn green-btn new-green"
            onClick={differentUserSubmitHandler}
            style={{
              backgroundColor:
                tableReducer?.table_detail?.QrOrdering?.brand_main_color,
            }}
          >
            No, login as different user
          </a>
        </div>
      </div>
      

      {/* <Motion
        defaultStyle={{ x: 100 }}
        style={{
          x: spring(!redirectEberLogin ? 0 : 100, {
            precision: 1,
            stiffness: 300,
            damping: 44,
          }),
        }}
      >
        {({ x }) => {
          return (
            <LoginEber
              style={{
                right: `-${x}%`,
              }}
              customerType={redirectEberLogin}
              setCustomerType={setRedirectEberLogin}
            />
          );
        }}
      </Motion> */}


      <Modal
        show={showTermsPopup}
        title={""}
        extraClass={"top50"}
        modalbody={<Terms />}
        OnSubmit={() => setShowTermsPopup(false)}
        submitBtnText="ok"
      />
    </LoginLayout>
  );
};

export default UserConfirmation;
