import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ImageTag from "./ImageTag";
import { green_loc_mark, left_arrow_green } from "../../Assets";
import { StyledSVG } from "../../Utils/style";
import AppHeader from "./AppHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LoginLayout = (props) => {
  const {
    left,
    backHandler,
    showBackBtn,
    children,
    divClass = "",
    style = {},
  } = props;
  const { table_detail } = useSelector((state) => state.tableDetailReducer);
   console.log(table_detail)
  const calculateIsToolBarStatus = () => {
    let res = false;
    let maxHeight = window.outerHeight - window.innerHeight;
    if (maxHeight > 120) {
      res = true;
    }
    return res;
  };

  const [totalHeight, setTotalHeight] = useState(window.innerHeight);
  const totalHeightRef = useRef(totalHeight);
  totalHeightRef.current = totalHeight;
  const [isToolBarHide, setIsToolBarHide] = useState(
    calculateIsToolBarStatus()
  );
  const history=useHistory()

  useEffect(() => {
    setIsToolBarHide(calculateIsToolBarStatus());
  }, [totalHeight]);

  useEffect(() => {
    window.addEventListener("resize", function (event) {
      setTotalHeight(window.innerHeight);
    });
  }, []);

 

  return (
    <div
      className={`wrapper${isToolBarHide ? "" : " toolbar-vh"} ${divClass}`}
      style={style}
    >
      {/* <div className="overlay"> </div> */}
      {/* <div className="container p-5">
        <div className="row header_top mx-0">
          <div class="col-6 px-0">
            
            {showBackBtn && (
              <div className="d-flex align-items-center">
              <StyledSVG color={table_detail?.QrOrdering?.brand_main_color} src={left_arrow_green} width="8" />

              <a role={"button"} onClick={backHandler} class="back pl-3"
            style={{color:table_detail?.QrOrdering?.brand_main_color}}
            >
                {" "}

                BACK{" "}
              </a>
              </div>
            )}
          </div>
        </div>
      </div> */}
      <AppHeader table_detail={table_detail} backButton={showBackBtn} backHandler={backHandler} left={left}/>
      <section className="varification-sec customer-ms">
        {/* <a href="#_" className="cross-icon"></a> */}
        {/* <div className="circle-img imgLogo">
          <figure className="">
            <ImageTag src={table_detail?.Merchant?.logo} alt="" />
          </figure>
        </div> */}

        {/* <div className="header-title pt-0 px_15">
          <h5 className="title">
            {" "}
            <span className="loc-icon">
            <StyledSVG color={table_detail?.QrOrdering?.brand_main_color} src={green_loc_mark} width="20" />{" "}

              
              </span>{" "}
            {table_detail?.Merchant?.account_name}
          </h5>
          <h2>
            {" "}
            Table{" "}
            <strong> {table_detail?.PosTableMaster?.table_number} </strong>{" "}
          </h2>
        </div> */}
        {children}
      </section>
    </div>
  );
};
export default LoginLayout;
